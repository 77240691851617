<template>
  <div class="cyberinsurancestatus" id="cyberinsurance-status">
    <CyberInsuranceLoader
      id="cyberinsurance-loader"
      :class="{ packageDiv: formPackage }"
    />
    <CyberInsurancePending
      id="cyberinsurance-pending"
      :class="{ packageDiv: formPending }"
    />
    <CyberInsuranceSuccess
      id="cyberinsurance-success"
      :class="{ packageDiv: formSuccess }"
    />
    <CyberInsuranceFailed
      id="cyberinsurance-failed"
      :class="{ packageDiv: formFailed }"
    />
  </div>
</template>

<style lang="scss" scoped>
.packageDiv {
  display: none !important;
}
</style>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";
import CyberInsuranceLoader from "../components/MicroSite/CyberInsuranceLoader.vue";
import CyberInsurancePending from "../components/MicroSite/CyberInsurancePending.vue";
import CyberInsuranceSuccess from "../components/MicroSite/CyberInsuranceSuccess.vue";
import CyberInsuranceFailed from "../components/MicroSite/CyberInsuranceFailed.vue";

export default {
  name: "cyberinsurance-status",
  data() {
    return {
      formPackage: false,
      formPending: true,
      formSuccess: true,
      formFailed: true,
      refKey: null,
    };
  },
  methods: {
    getSessionDataWithExpiry(key) {
      const itemStr = sessionStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        // Item has expired, remove it from sessionStorage
        sessionStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    getDecryptedData(data) {
      const encryptionKey = "your-secret-key";
      if (data) {
        const decryptedData = CryptoJS.AES.decrypt(data, encryptionKey);
        const decryptedDataString = decryptedData.toString(CryptoJS.enc.Utf8);
        const jsonDataReceived = JSON.parse(decryptedDataString);
        return jsonDataReceived;
      } else {
        return null;
      }
    },
    async getDataCallBack() {
      var formData = new FormData();

      this.formPackage = true;
      this.formPending = true;
      this.formSuccess = true;
      this.formFailed = true;

      formData.append("refKey", this.refKey);
      try {
        const response = await axios.post(
          "https://insuranceapi.tekkis.my/Webv2/microsite/requeryData",
          formData,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        var dataJson = response.data.paymentInfo;
        if (
          dataJson.status === "pending" ||
          dataJson.status === "pending_transaction"
        ) {
          this.formPending = false;
        } else if (dataJson.status === "completed") {
          this.formSuccess = false;
          // sessionStorage.removeItem("infoUser");
        } else if (
          dataJson.status === "failed" ||
          dataJson.status === "rejected"
        ) {
          this.formFailed = false;
        }
      } catch (error) {
        // Handle network or request errors
        // console.error("Network or request error:", error);
        setTimeout(function () {
          location.href = "/cyberinsurance";
        }, 2000);
        this.formPackage = true;
        this.formPending = true;
        this.formSuccess = true;
        this.formFailed = false;
      }
    },
  },
  components: {
    CyberInsuranceSuccess,
    CyberInsuranceFailed,
    CyberInsuranceLoader,
    CyberInsurancePending,
  },

  mounted() {
    // Make the API request when the component is mounted
    const encryptedData = this.getSessionDataWithExpiry("infoUser");
    if (encryptedData != null) {
      const decryptedData = this.getDecryptedData(encryptedData);
      this.receivedData = decryptedData;
      this.refKey = decryptedData.refKey;
    } else {
      setTimeout(function () {
        location.href = "/cyberinsurance";
      }, 2000);
    }

    this.getDataCallBack();
  },
};
</script>
